<!--
 * @Description: 车辆用户管理系统 运维管理 优惠活动 cuDiscountActivity
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="活动名称">
              <el-input v-model="searchForm.activityName"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="活动状态"
                          label-width="90px">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.activityStatus"
                         placeholder="活动状态">
                <el-option v-for="item in activityStatusList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="活动时间"
                          label-width="90px">
              <el-date-picker v-model="activityTime"
                              type="daterange"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button @click="query"
                     type="primary">查 询</el-button>
          <el-button @click="reset"
                     type="info"
                     style="margin-left:17px">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>优惠活动</span>
        <el-button class="tableTitleButton"
                   type="primary"
                   @click="addRule">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table ref="table"
                  :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="活动图标"
                             prop="activityIcon">
              <template slot-scope="scope">
                <a :href="$imgBaseUrl+scope.row.activityIcon"
                   target="_blank"
                   style="color:#019fe4">预览</a></template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="活动状态"
                             prop="activityStatusName">
              <template slot-scope="scope">
                <span :style="scope.row.activityStatus==='0'? 'color:#F63B24;':'color:#00D03F;'">{{scope.row.activityStatusName}}</span>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="9">
                    <el-button @click="set(scope.row)"
                               type="text"
                               size="medium">规则配置</el-button>
                  </el-col>
                  <el-col :span="5">
                    <el-button @click="detailsClick(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="5">
                    <el-button @click="update(scope.row)"
                               type="text"
                               size="medium">编辑</el-button>
                  </el-col>
                  <el-col :span="5">
                    <el-button @click="remove(scope.row)"
                               type="text"
                               size="medium">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 弹窗 dialogType= 0 新增 1编辑 2详情 -->
    <el-dialog :title="dialogTitle"
               :visible.sync="distributeVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="popClose">
      <el-form ref="popForm"
               :model="popForm"
               :rules="popRules"
               label-position="left"
               label-width="148px">
        <el-row class="popRow">
          <el-col :span="12">
            <el-form-item prop="activityName"
                          label="优惠规则名称">
              <el-input v-model="popForm.activityName"
                        clearable
                        :readonly="dialogType===2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="ruleDes"
                          label="活动描述">
              <el-input v-model="popForm.ruleDes"
                        clearable
                        :readonly="dialogType===2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="popRow">
          <el-col :span="12">
            <el-form-item prop="startTime"
                          label="活动开始时间">
              <el-date-picker v-model="popForm.startTime"
                              placeholder="选择开始时间"
                              type="date"
                              value-format="yyyy-MM-dd 00:00:00"
                              :readonly="dialogType===2" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="endTime"
                          label="活动结束时间">
              <el-date-picker v-model="popForm.endTime"
                              placeholder="选择结束时间"
                              type="date"
                              value-format="yyyy-MM-dd 23:59:59"
                              :readonly="dialogType===2" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="popRow">
          <el-col :span="12">
            <el-form-item prop="activityStatus"
                          label="活动状态">
              <el-select class="search-input"
                         clearable
                         :disabled="dialogType===2"
                         v-model="popForm.activityStatus"
                         placeholder="活动状态">
                <el-option v-for="item in activityStatusList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="activityBackground"
                          label="活动背景色">
              <el-color-picker v-model="popForm.activityBackground"
                               :disabled="dialogType===2"></el-color-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="popRow">
          <el-form-item v-if="dialogType!==2"
                        label="优惠活动规则描述"
                        prop="activityContent">
            <richText ref="richText"
                      style="width: 675px;"
                      v-model="popForm.activityContent"
                      :height="220"
                      :uploadImgMaxLength="6"
                      @uploadImg="uploadImg"></richText>

          </el-form-item>
          <div v-else>
            <el-form-item label="优惠活动规则描述"
                          prop="activityContent">
            </el-form-item>
            <el-row class="richArea"
                    v-html="popForm.activityContent"></el-row>
          </div>
        </el-row>
        <el-row class="popRow">
          <el-col :span="12">
            <el-form-item label="活动头图"
                          prop="activityHeadImg">
              <el-upload class="avatar-uploader"
                         action=""
                         list-type="picture-card"
                         :file-list="imgList"
                         :limit='1'
                         :disabled="dialogType===2"
                         v-model="popForm.activityHeadImg"
                         :on-remove="removeImg"
                         :http-request='imgUpload'>
                <el-image class="avatar"
                          v-show="dialogType===2"
                          :src="$imgBaseUrl+popForm.activityHeadImg" />
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活动图标"
                          prop="activityIcon">
              <el-upload class="avatar-uploader"
                         action=""
                         list-type="picture-card"
                         :file-list="iconList"
                         :limit='1'
                         :disabled="dialogType===2"
                         v-model="popForm.activityIcon"
                         :http-request='iconUpload'>
                <el-image class="avatar"
                          v-show="dialogType===2"
                          :src="$imgBaseUrl+popForm.activityIcon" />
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="popRow">
          <el-col :span="12">
            <el-form-item prop="isShare"
                          label="是否允许用户分享">
              <el-select class="search-input"
                         clearable
                         :disabled="dialogType===2"
                         v-model="popForm.isShare">
                <el-option v-for="item in isShareList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="shareTitle"
                          label="分享标题">
              <el-input v-model="popForm.shareTitle"
                        clearable
                        :readonly="dialogType===2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="shareContent"
                      label="使用说明"
                      class="popRow">
          <el-input v-model="popForm.shareContent"
                    clearable
                    style="width: 675px;"
                    type="textarea"
                    :rows="3"
                    :readonly="dialogType===2"></el-input>
        </el-form-item>

        <el-row v-show="dialogType===2">
          <el-row class="popRow">
            <el-col :span="12">
              <el-form-item label="创建人">
                <el-input v-model="popForm.createName"
                          readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <el-input v-model="popForm.createTime"
                          readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="popRow">
            <el-col :span="12">
              <el-form-item label="最后修改人">
                <el-input v-model="popForm.updateName"
                          readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="修改时间">
                <el-input v-model="popForm.updateTime"
                          readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="活动链接">
            <el-input v-model="popForm.activityUrl"
                      style="width: 675px;"
                      type="textarea"
                      :rows="1"
                      readonly></el-input>
          </el-form-item>
        </el-row>
        <el-row class="popRow">
          <el-form-item v-show="dialogType!==2">
            <el-button type="primary"
                       @click="submitForm">保存</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 详情弹窗 -->
    <el-dialog title="优惠规则详情"
               :visible.sync="detailsVisible"
               append-to-body
               @close="popClose">

      <el-row class="textOne">活动信息</el-row>
      <el-row class="popRow details-content">
        <el-row class="content">
          <el-row>
            <el-col class="textOne">{{ popForm.activityName }}
              <span style="margin-left:20px;font-size:12px"
                    :style="popForm.activityStatus==='0'? 'color:#F63B24':'color:#00D03F'">
                {{ popForm.activityStatusName}}</span>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col class="textTwo"
                    :lg="4"
                    :xl="2">开始时间：</el-col>
            <el-col class="textThree"
                    :lg="7"
                    :xl="5">{{popForm.startTime}}</el-col>
            <el-col class="textTwo"
                    :lg="4"
                    :xl="2">结束时间：</el-col>
            <el-col class="textThree"
                    :lg="7"
                    :xl="5">{{popForm.endTime}}</el-col>
            <el-col class="textTwo"
                    :lg="4"
                    :xl="3">活动背景色：
            </el-col>
            <el-col :span="1">
              <span class="color"
                    :style="'background:'+popForm.activityBackground" />
            </el-col>
            <el-col class="textThree"
                    :span="5">
              {{popForm.activityBackground}}
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col class="textTwo"
                    :lg="4"
                    :xl="2">活动头图：</el-col>
            <el-col class="textThree"
                    :lg="7"
                    :xl="5">
              <el-image style="width:150px;height:100px"
                        :src="$imgBaseUrl+popForm.activityHeadImg" />
            </el-col>

            <el-col class="textTwo"
                    :lg="4"
                    :xl="2">活动图标：</el-col>
            <el-col class="textThree"
                    :lg="7"
                    :xl="5">
              <el-image style="width:150px;height:100px"
                        :src="$imgBaseUrl+popForm.activityIcon" />
            </el-col>
          </el-row>
        </el-row>
      </el-row>
      <el-row class="popRow"
              type="flex">
        <el-col class="textTwo"
                :lg="4"
                :xl="2">活动描述：</el-col>
        <el-col class="textThree">{{ popForm.activityContent}}</el-col>
      </el-row>
      <el-row class="popRow"
              type="flex">
        <el-col class="textTwo"
                :lg="4"
                :xl="2">规则描述：</el-col>
        <el-col class="textThree">{{popForm.ruleDes}}</el-col>
      </el-row>
      <el-row class="popRow"
              type="flex">
        <el-col class="textTwo"
                :lg="4"
                :xl="2">活动链接：</el-col>
        <el-col class="textThree">www.baidu.com</el-col>
      </el-row>
      <el-row class=" popRow textOne">分享设置</el-row>
      <el-row class="popRow"
              type="flex">
        <el-col class="textTwo"
                :lg="6"
                :xl="4">是否允许用户分享：</el-col>
        <el-col class="textThree"> {{popForm.isShare==='0'?"是（系统默认）":"否"}}</el-col>
      </el-row>
      <el-row class="popRow"
              type="flex">
        <el-col class="textTwo"
                :lg="4"
                :xl="2">分享标题：</el-col>
        <el-col class="textThree">{{popForm.shareTitle}}</el-col>
      </el-row>
      <el-row class="popRow"
              type="flex">
        <el-col class="textTwo"
                :lg="4"
                :xl="2">分享文案：</el-col>
        <el-col class="textThree">{{popForm.shareContent}}</el-col>
      </el-row>
      <el-row class="popRow textOne">编辑相关</el-row>
      <el-row class="popRow"
              type="flex">
        <el-col class="textTwo"
                :lg="4"
                :xl="2">创建人：</el-col>
        <el-col class="textThree"
                :lg="8"
                :xl="5">{{popForm.createName}}</el-col>

        <el-col class="textTwo"
                :lg="4"
                :xl="2">创建时间：</el-col>
        <el-col class="textThree"
                :lg="8"
                :xl="5">{{popForm.createTime}}</el-col>
      </el-row>
      <el-row class="popRow down"
              type="flex">
        <el-col class="textTwo"
                :lg="4"
                :xl="3">最后修改人：</el-col>
        <el-col class="textThree"
                :lg="8"
                :xl="4">{{popForm.updateName}}</el-col>
        <el-col class="textTwo"
                :lg="4"
                :xl="3">修改时间：</el-col>
        <el-col class="textThree"
                :lg="8"
                :xl="5"> {{popForm.updateTime}}</el-col>
      </el-row>
      <!-- 预览按钮 -->
      <el-button type="primary"
                 @click="viewClick">预览</el-button>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import richText from "@/components/richText.vue";
import Compressor from 'compressorjs'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    richText
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
        chargCode: '',
        chargName: '',
        operName: '',
      },
      pageNum: 1,
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'activityName',
          label: '活动名称',
        },
        {
          prop: 'activityContent',
          label: '活动内容',
        },
        {
          prop: 'startTime',
          label: '活动开始时间',
        },
        {
          prop: 'endTime',
          label: '活动结束时间',
        },
        {
          prop: 'ruleDes',
          label: '规则描述',
        }
      ],
      tableList: {
        list: [],
      },
      searchForm: {

      },
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      // 弹出框标题
      dialogTitle: '',
      // 弹出框类型 0 新增，1修改，2查看
      dialogType: null,
      // 弹出框是否打开
      distributeVisible: false,
      // 弹出框表单
      popForm: {
      },
      popRules: {
        activityName: [
          { required: true, message: '请输入优惠活动名称', trigger: 'blur' }
        ],
        ruleDes: [
          { required: true, message: '请输入优惠活动描述', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '请选择活动开始时间', trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '请选择活动结束时间', trigger: 'change' }
        ],
        activityStatus: [
          { required: true, message: '请选择活动状态', trigger: 'change' }
        ],
        activityBackground: [
          { required: true, message: '请选择活动背景色', trigger: 'change' }
        ],
        activityContent: [
          { required: true, message: '请输入优惠活动规则描述', trigger: 'blur' }
        ],
        // activityHeadImg: [
        //   { required: true, message: '请上传头图', trigger: 'change' }
        // ],
        // activityIcon: [
        //   { required: true, message: '请上传活动图标', trigger: 'change' }
        // ],
        isShare: [
          { required: true, message: '请选择是否允许分享', trigger: 'change' }
        ],
        shareTitle: [
          { required: true, message: '请输入分享标题', trigger: 'blur' }
        ],
        shareContent: [
          { required: true, message: '请输入使用说明', trigger: 'blur' }
        ]
      },
      // 活动状态下拉框
      activityStatusList: [],
      //活动时间
      activityTime: [],
      // 分享
      isShareList: [],
      // 头图
      imgList: [],
      // 图标
      iconList: [],
      // 详情弹窗
      detailsVisible: false

    }

  },
  filters: {
    filteIco (data) {
      return data.split('/').slice(-1)[0]
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getActivityStatus()
    this.getIsShare()
  },
  //方法集合
  methods: {
    // 优惠活动状态下拉框 
    getActivityStatus () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F3AE2F2D87FB4F00A69SEUYUOKLCHKJIIIEEOD',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.activityStatusList = response.resultEntity
        this.getActivityList()
      })
    },
    // 是否分享下拉框
    getIsShare () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F3AEIOEYJLKHFKSOPEOUYHWQ',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.isShareList = response.resultEntity
      })

    },
    //列表
    getActivityList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$cuDiscountActivity.select(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.tableList.list.forEach(item => {
          //将优惠类型编码转汉字
          this.activityStatusList.forEach(element => {
            if (item.activityStatus == element.code) {
              item['activityStatusName'] = element.name
            }
          })
        })
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.getActivityList()
    },
    // 查询
    query () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.activityTime) {
        this.searchForm.startTime = this.activityTime[0]
        this.searchForm.endTime = this.activityTime[1]
      }
      else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.getActivityList()
    },
    // 重置
    reset () {
      this.searchForm = {}
      this.activityTime = []
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.getActivityList()
    },

    // 新增按钮
    addRule () {
      this.dialogTitle = '优惠活动新增'
      this.dialogType = 0
      this.distributeVisible = true
      this.$refs.richText?.setValue('')
      if (this.$refs.popForm) {
        this.$refs.popForm.clearValidate()
      }
    },
    // 点击编辑
    update (row) {
      this.popForm = this.$deepClone(row)
      this.imgList = [
        {
          url: this.$imgBaseUrl + this.popForm.activityHeadImg
        }
      ]
      this.iconList = [
        {
          url: this.$imgBaseUrl + this.popForm.activityIcon
        }
      ]
      this.$refs.richText?.setValue(this.popForm.activityContent)
      this.dialogTitle = '优惠活动修改'
      this.dialogType = 1
      this.distributeVisible = true
    },
    // 点击详情
    detailsClick (row) {
      this.popForm = this.$deepClone(row)
      this.detailsVisible = true
    },
    //弹窗关闭
    popClose () {
      this.popForm = {}
      this.imgList = []
      this.iconList = []
    },
    // 保存按钮
    submitForm () {
      this.$refs.popForm.validate((valid) => {
        if (valid) {
          if (this.dialogType === 0) {
            this.addRequest()
          } else if (this.dialogType === 1) {
            this.updateRequest()
          }
        } else {
          return false;
        }
      });
    },
    // 新增请求
    addRequest () {
      let info = {
        activityName: this.popForm.activityName,
        ruleDes: this.popForm.ruleDes,
        startTime: this.popForm.startTime,
        endTime: this.popForm.endTime,
        activityBackground: this.popForm.activityBackground,
        activityContent: this.popForm.activityContent,
        activityHeadImg: this.popForm.activityHeadImg,
        activityIcon: this.popForm.activityIcon,
        activityStatus: this.popForm.activityStatus,
        isShare: this.popForm.isShare,
        shareTitle: this.popForm.shareTitle,
        shareContent: this.popForm.shareContent,
      }
      if (this.popForm.startTime > this.popForm.endTime) {
        this.$message.error('活动开始时间应小于活动结束时间')
      } else if (!this.popForm.activityHeadImg || !this.popForm.activityIcon) {
        this.$message.error('请上传活动头图和活动图标')
      }
      else {
        this.$cuDiscountActivity.insert(info).then(() => {
          this.$message(
            {
              message: '新增优惠活动成功',
              type: 'success'
            }
          )
          this.distributeVisible = false
          this.getActivityList()
        }).catch(() => {
          this.$message.error('新增失败')
        })
      }

    },
    // 修改请求
    updateRequest () {
      let info = {
        activityId: this.popForm.activityId,
        activityName: this.popForm.activityName,
        ruleDes: this.popForm.ruleDes,
        startTime: this.popForm.startTime,
        endTime: this.popForm.endTime,
        activityBackground: this.popForm.activityBackground,
        activityContent: this.popForm.activityContent,
        activityHeadImg: this.popForm.activityHeadImg,
        activityIcon: this.popForm.activityIcon,
        activityStatus: this.popForm.activityStatus,
        isShare: this.popForm.isShare,
        shareTitle: this.popForm.shareTitle,
        shareContent: this.popForm.shareContent,
      }
      if (this.popForm.startTime > this.popForm.endTime) {
        this.$message.error('活动开始时间应小于活动结束时间')
      } else if (!this.popForm.activityHeadImg || !this.popForm.activityIcon) {
        this.$message.error('请上传活动头图和活动图标')
      }
      else {
        this.$cuDiscountActivity.update(info).then(() => {
          this.$message(
            {
              message: '修改优惠活动成功',
              type: 'success'
            }
          )
          this.distributeVisible = false
          this.getActivityList()
        }).catch(() => {
          this.$message.error('修改失败')
        })
      }
    },
    //删除
    remove (row) {
      this.$confirm('是否确定删除该优惠活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cuDiscountActivity.activityDelete({ activityId: row.activityId }).then(() => {
          this.getActivityList()
        })
      })
    },
    // 富文本上传图片
    uploadImg (resultFiles, insertImgFn) {
      resultFiles.forEach(file => {
        // 压缩图片
        const self = this
        new Compressor(file, {
          quality: 0.2,
          success (result) {
            // 将 Blob 对象转换成 File 对象
            result = new window.File([result], result.name, { type: result.type })
            const formData = new FormData()
            formData.append('file', result)
            self.$queryDict.upFile(formData).then(res => {
              insertImgFn(self.$imgBaseUrl + res.resultEntity)
            })
          },
          error () {
          }
        })
      })
    },
    // 头图
    imgUpload (file) {
      const self = this
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$queryDict.upFile(formData).then(res => {
            self.popForm.activityHeadImg = res.resultEntity
          }).catch(() => {
            self.$message.error('图片上失败')
          })
        },
        error () {
        }
      })
    },
    // 图标
    iconUpload (file) {
      const self = this
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$queryDict.upFile(formData).then(res => {
            self.popForm.activityIcon = res.resultEntity
          }).catch(() => {
            self.$message.error('图片上失败')
          })
        },
        error () {
        }
      })
    },
    set (row) {
      this.$router.push({
        path: '/cuDiscountAcRule', query: { activityId: row.activityId }
      })
    },
    removeImg (file, fileList) {
      console.log(file, fileList, 'file,fileList');
      // this.imgList = []
    },
    // 点击预览按钮
    viewClick () {
      this.$router.push({
        path: '/cuDiscountActivityDetails',
        query: { popForm: this.popForm }
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    @include background('blockBg');
    height: $topSelectHeight;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
        margin-left: 17px;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.search_column1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.search_column2 {
  margin-top: 20px;
}
.popRow {
  margin-bottom: 25px;
}
.richArea {
  height: 200px;
  padding: 10px;
  margin-top: 20px;
  background: #ffffff;
  overflow: auto;
}
/*修改滚动条样式*/
// 滚动条最底部样式
.richArea::-webkit-scrollbar {
  width: 5px;
  background: #ffffff;
  /**/
}
// 滚动条背景条样式
.richArea::-webkit-scrollbar-track {
  background: #ffffff;
}
// 滚动条上层可滑动的条样式
.richArea::-webkit-scrollbar-thumb {
  background: #004179;
  border-radius: 5px;
}
// 滚动条上层可滑动的条样式:hover样式
.richArea::-webkit-scrollbar-thumb:hover {
  background: #013b6d;
}
//头图
.avatar-uploader {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 148px;
  height: 148px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  border: none;
}
.details-content {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 20px;
  background: #122849;
  .content {
    margin-bottom: 15px;
    .el-col {
      padding-top: 15px;
    }
  }
}
.textOne {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #f8fdff;
}
.textTwo {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #70aee8;
}
.textThree {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #bae8ff;
}
.color {
  display: inline-block;
  margin-right: 20px;
  width: 16px;
  height: 16px;
  border: 1px solid #1b3864;
  border-radius: 1px;
}
.down {
  .el-col {
    padding-top: 1px;
  }
}
</style>

